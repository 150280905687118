(() => {
  // Debounce
  const debounce = (func, time) => {
    var time = time || 100; // 100 by default if no param
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, time, event);
    };
  };

  // thresholds for the observer
  const threshold: number[] = [0.1, 0.5, 0.9, 1];

  // callback for intersection observer
  const callback = entries => {
    const entry = entries[0];
    // when the animate screen is on top of the screen,
    if (entry.isIntersecting && entry.boundingClientRect.top <= 0) {
      let totalHeight = entry.boundingClientRect.height;
      const initialScale = 3;
      const activeClass = 'journey-treatment-section--in-view';
      const parent = entry.target?.closest('.journey-treatment-section');
      const headerEl = parent.querySelector(
        '.image-map-section__heading'
      ) as HTMLElement;
      const hiddenHeaderEl = parent.querySelector(
        '.image-map-section__heading--main'
      ) as HTMLElement;

      let curScrollPos = parent.offsetTop;
      let winWidth = window.innerWidth;
      let winHeight = window.innerHeight;

      const changeScale = () => {
        const newScrollPos = window.scrollY;
        const diff = newScrollPos - curScrollPos;
        const diffPercent = (diff * 100) / totalHeight;

        // reducing the same percentage of scroll from the scale value
        let nextScale = Math.min(
          initialScale,
          Math.max(1, initialScale - (diffPercent / 100) * initialScale)
        );

        // when the hidden header element is half way through the screen, show the hidden header element, remove the scroll listener
        // 49 is the transformed position of the header
        if (hiddenHeaderEl.getBoundingClientRect().top + 49 <= winHeight / 2) {
          parent.classList.add(activeClass);
          window.removeEventListener('scroll', changeScale);
          observer.observe(entry.target);
        } else {
          parent.classList.remove(activeClass);
        }

        headerEl.style.transform = `scale(${nextScale})`;
      };

      // on page load if the header element is present above the viewport, making sure that the header is scaled appropriately
      if (curScrollPos < window.scrollY) {
        changeScale();
      }

      // resetting the scroll and the other variables on window resize
      const resetScroll = () => {
        const curWidth = window.innerWidth;
        if (winWidth !== curWidth) {
          winHeight = window.innerHeight;
          headerEl.style.transform = `scale(${initialScale})`;
          parent.classList.remove(activeClass);
          window.removeEventListener('scroll', changeScale);
          observer.observe(entry.target);
          curScrollPos = parent.offsetTop - 100;
          changeScale();
        }
      };

      window.addEventListener('resize', debounce(resetScroll, 150));

      window.addEventListener('scroll', changeScale);

      observer.unobserve(entry.target);
    }
  };

  // the main observer
  const observer = new IntersectionObserver(callback, {
    threshold,
  });

  // initializes the animation
  const initAnimation = () => {
    const animatorEl = document.querySelectorAll('.journey-treatment-section');
    if (animatorEl?.length) {
      animatorEl.forEach(el => {
        const bufferEl = el.querySelector(
          '.journey-treatment-section__animator'
        ) as HTMLElement;
        const headerEl = el?.querySelector(
          '.image-map-section__heading'
        ) as HTMLElement;

        // adding observer
        if (bufferEl && headerEl) {
          observer.observe(bufferEl);
        }
      });
    }
  };

  const init = () => {
    const jdPage = document.querySelector(`#journey-dashboard`);
    if (jdPage) {
      initAnimation();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
