(() => {
  const handleHomeImgMap = () => {
    const homeImgMap = document.querySelector('.home__img-map');
    if (homeImgMap && window.Bus) {
      const mobTriggers = homeImgMap.querySelectorAll(
        '#home-img-map-mobile .emu-mapping-marker'
      ) as NodeListOf<HTMLButtonElement>;
      const deskTriggers = homeImgMap.querySelectorAll(
        '#home-img-map-desktop .emu-mapping-marker'
      ) as NodeListOf<HTMLButtonElement>;
      const triggerActiveClass = 'active';
      const contentClassPrefix = 'home__img-map-content--';
      const contentActiveClass = `${contentClassPrefix}show`;
      let timer;
      let progressTimer = {};
      const progressKeys = ['mobile', 'desktop'];

      // hides the active image map content
      // clears any previous progress animation timers
      // removes the progress canvas from the buttons
      const hideActiveImgMapContent = () => {
        // hiding active content
        const content = document.querySelector(`.${contentActiveClass}`);
        content?.classList.remove(contentActiveClass);

        // clearing previous progress animation
        if (progressTimer['mobile']) {
          clearInterval(progressTimer['mobile']);
          progressTimer['mobile'] = null;
        }

        // clearing previous progress animation
        if (progressTimer['desktop']) {
          clearInterval(progressTimer['desktop']);
          progressTimer['desktop'] = null;
        }

        // removing active class and canvas from mobile triggers
        mobTriggers.forEach(el => {
          el.classList.remove(triggerActiveClass);
          const canvas = el.querySelector('canvas');
          canvas?.remove();
        });

        // removing active class and canvas from desktop triggers
        deskTriggers.forEach(el => {
          el.classList.remove(triggerActiveClass);
          const canvas = el.querySelector('canvas');
          canvas?.remove();
        });
      };

      // showing active content
      const showImgMapContent = title => {
        hideActiveImgMapContent();
        const curContent = document.querySelector(
          `.${contentClassPrefix}${title}`
        );
        curContent?.classList.add(contentActiveClass);
      };

      // clicking corresponding desktop image map link when mobile map link is clicked.
      // all the logic is being handled on desktop image map link click
      const handleMobileImgMapClick = title => {
        const deskTitle = title.replace('-mobile', '');
        deskTriggers.forEach(el => {
          if (el.getAttribute('aria-label') === deskTitle) {
            el?.click?.();
          }
        });
      };

      // adds canvas to the array of CTAs and starts a progress animation on that canvas
      const addProgress = ctaArr => {
        // clearing previous mobile trigger animation
        if (progressTimer['mobile']) {
          clearInterval(progressTimer['mobile']);
          progressTimer['mobile'] = null;
        }

        // clearing previous desktop trigger animation
        if (progressTimer['desktop']) {
          clearInterval(progressTimer['desktop']);
          progressTimer['desktop'] = null;
        }

        ctaArr.forEach((cta, i) => {
          const canvas = document.createElement('canvas');
          cta.append(canvas);
          cta.classList.add(triggerActiveClass);

          const ctx = canvas.getContext('2d');
          let startPoint = 0;
          progressTimer[progressKeys[i]] = setInterval(() => {
            let val = (startPoint / 100) * Math.PI * 2;
            if (ctx) {
              ctx.clearRect(0, 0, 30, 30);
              ctx.strokeStyle = '#fff';
              ctx.beginPath();
              ctx.arc(125, 50, 12, 4.72, 4.72 + val); // logic from live site
              ctx.stroke();

              if (startPoint >= 100) {
                clearInterval(progressTimer[progressKeys[i]]);
                progressTimer[progressKeys[i]] = null;

                // hiding content after the whole animation is completed
                hideActiveImgMapContent();
              }
              startPoint += 1;
            }
          }, 60);
        });
      };

      // handles desktop image map trigger click
      // shows/hides the content
      // starts/stops the animation around the cta
      const handleDeskImgMapClick = title => {
        let curTrigger;
        let curMobileTrigger;

        if (timer) {
          clearTimeout(timer);
        }

        deskTriggers.forEach(el => {
          if (el.getAttribute('aria-label') === title) {
            curTrigger = el;
          }
        });

        mobTriggers.forEach(el => {
          if (el.getAttribute('aria-label') === `${title}-mobile`) {
            curMobileTrigger = el;
          }
        });

        // if it is a close action, hide the content and remove the active classes from buttons
        // else if it is a open button, hide active and show new content
        if (curTrigger) {
          // if the clicked element is already active, just hide the active content
          // else show the corresponding active content, and start progress animation around the cta
          if (curTrigger.classList.contains(triggerActiveClass)) {
            hideActiveImgMapContent();
          } else {
            showImgMapContent(title);
            addProgress([curTrigger, curMobileTrigger]);
          }
        }
      };

      window.Bus.on('emu-image-map:areaClick', ({ id, title }) => {
        if (id?.includes?.('home-img-map-mobile')) {
          handleMobileImgMapClick(title);
        } else if (id?.includes?.('home-img-map-desktop')) {
          handleDeskImgMapClick(title);
        }
      });

      window.Bus.on('emu-button:click', ({ id }) => {
        if (id.includes('img-map-close-')) {
          hideActiveImgMapContent();
        }
      });
    }
  };

  // in home page, wait until the image map becomes available and then start adding logic for managing click events of the buttons in image map
  if (document.querySelector('#home')) {
    // method from aaaem common, used for image map
    const waitForElm = selector => {
      return new Promise(resolve => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }
        const observer = new MutationObserver(() => {
          if (document?.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
          }
        });
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      });
    };

    waitForElm('.home__img-map .emu-mapping-marker').then(handleHomeImgMap);
  }
})();
