const _temp0 = require("./components/accordion/_accordion.scss");

const _temp1 = require("./components/carousel/_carousel.scss");

const _temp2 = require("./components/comparison-slider/_comparison-slider.scss");

const _temp3 = require("./components/container-isi/_container-isi.scss");

const _temp4 = require("./components/fap/_fap.scss");

const _temp5 = require("./components/footer/_footer.scss");

const _temp6 = require("./components/header/_header-sticky.scss");
const _temp7 = require("./components/header/_header.scss");
const _temp8 = require("./components/header/_nav.scss");

const _temp9 = require("./components/image-map/_image-map-redesign.scss");
const _temp10 = require("./components/image-map/_image-map.scss");

const _temp11 = require("./components/modal/_modal.scss");

const _temp12 = require("./components/teaser/_teaser.scss");

const _temp13 = require("./components/text/_text.scss");

module.exports = {
  "accordion":   {
    "_accordion": _temp0
  },
  "carousel":   {
    "_carousel": _temp1
  },
  "comparison-slider":   {
    "_comparison-slider": _temp2
  },
  "container-isi":   {
    "_container-isi": _temp3
  },
  "fap":   {
    "_fap": _temp4
  },
  "footer":   {
    "_footer": _temp5
  },
  "header":   {
    "_header-sticky": _temp6,
    "_header": _temp7,
    "_nav": _temp8
  },
  "image-map":   {
    "_image-map-redesign": _temp9,
    "_image-map": _temp10
  },
  "modal":   {
    "_modal": _temp11
  },
  "teaser":   {
    "_teaser": _temp12
  },
  "text":   {
    "_text": _temp13
  }
}