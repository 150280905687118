/**
 * This file contains the code corresponding to journey page
 */

(() => {
  // take-closer-look section in journey page
  const handleTakeCloserLookSection = () => {
    const accordionInstance = window._badgerInstances?.['take-closer-look'];
    const teasers = document.querySelectorAll(
      '.take-closer-look-section .teaser'
    );

    // when accordion item is expanded, show the corresponding teaser image
    const makeImageActive = (items, activeIndex?) => {
      let curIndex = activeIndex;

      if (items?.length) {
        items.forEach((item, i) => {
          if (item.opened) {
            curIndex = i;
          }
        });
      }

      if (curIndex || curIndex === 0) {
        teasers.forEach((teaser, index) => {
          teaser.classList.toggle('active', curIndex === index);
        });
      }
    };

    if (accordionInstance && window.Bus) {
      makeImageActive(null, 0);
      window.Bus.on('emu-accordion:toggle', ({ id, items }) => {
        if (id === 'take-closer-look') {
          accordionInstance.calculateAllPanelsHeight?.();
          makeImageActive(items);
        }
      });
    }

    const mobileCarouselId = 'jp-take-closer-look-mobile-carousel';
    const mobileTakeALookCarousel = document.querySelector(
      `#${mobileCarouselId}`
    ) as HTMLElement;
    const carouselInst =
      window._tnsInstances?.['jp-take-closer-look-mobile-carousel'];

    // making sure that the carousel height is updated appropriately
    if (carouselInst) {
      const observer = new IntersectionObserver(entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          carouselInst.updateSliderHeight?.();
        }
      });

      observer.observe(mobileTakeALookCarousel);
    }
  };

  // treatment-needs-section in journey page
  const handleTreatmentNeeds = wrapper => {
    const isNotTouch = matchMedia('(hover: hover) and (pointer: fine)').matches;

    const treatmentSections = wrapper?.querySelectorAll(
      '.jp__treatment-needs-section'
    );
    const treatmentTitles = wrapper?.querySelectorAll(
      '.jp__treatment-needs-section-title'
    );
    let activeIndex = 0;
    let timer;

    if (treatmentTitles?.length) {
      const activateSection = index => {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }

        if (index === activeIndex) {
          return;
        }

        const curActiveSection = treatmentSections[activeIndex];
        curActiveSection?.classList?.remove?.(
          'jp__treatment-needs-section--active'
        );

        const nextActiveSection = treatmentSections[index];
        activeIndex = index;
        if (isNotTouch) {
          nextActiveSection?.classList?.add?.(
            'jp__treatment-needs-section--active'
          );
        } else {
          nextActiveSection.scrollIntoView();
          timer = setTimeout(() => {
            nextActiveSection?.classList?.add?.(
              'jp__treatment-needs-section--active'
            );
            timer = null;
          }, 400);
        }
      };

      treatmentTitles.forEach((title, i) => {
        if (isNotTouch === true) {
          title.addEventListener('mouseover', () => {
            activateSection(i);
          });
        }

        title.addEventListener('click', e => {
          if (
            e.target?.getAttribute?.('href') ||
            e.currentTarget?.getAttribute?.('href')
          ) {
            e.preventDefault();
          }
          activateSection(i);
        });
      });
    }
  };

  const handleFocusIsOnOptions = () => {
    const selectElement = document.getElementById('focus-is-on-options');
    const optionElements = selectElement?.querySelectorAll('option');

    optionElements?.forEach(option => {
      if (option.selected) {
        const selectedCard = document.querySelector(`.${option.value}`);
        selectedCard?.classList.add(`jp__focus-is-on-card--show`);
      }
    });

    window.Bus.on('emu-form-select:change', ({ selectOptions, id }) => {
      if (id === 'focus-is-on-options-select') {
        selectOptions.map(option => {
          const selectedCard = document.querySelector(`.${option.value}`);
          selectedCard?.classList.toggle(
            `jp__focus-is-on-card--show`,
            option.selected
          );
        });
      }
    });
  };

  // initialization
  const initJourneyPage = () => {
    // apply only if it is journey page
    if (document.body.id !== 'journey-page') {
      return;
    }

    const treatmentNeedsWrapper = document.querySelectorAll('.treatment-needs');
    if (treatmentNeedsWrapper?.length) {
      treatmentNeedsWrapper.forEach(el => {
        handleTreatmentNeeds(el);
      });
    }
    handleTakeCloserLookSection();
    handleFocusIsOnOptions();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initJourneyPage);
  } else {
    initJourneyPage();
  }
})();
