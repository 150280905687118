const CONSTANTS = {
  navClassName: 'jd__page-navigation',
  activeLinkClassName: 'emu-navigation__item--highlighted',
  sections: [],
  activeSectionIndex: 0,
  links: [],
  listItems: [],
  offsets: [],
};

(() => {
  const bindSectionLink = (
    observationDiv: HTMLElement,
    sideLinkIndex: number,
    listItems
  ) => {
    if (observationDiv === null) return;

    const isElementActiveAndVisible = (
      entry: IntersectionObserverEntry
    ): boolean => {
      return entry.isIntersecting;
    };

    const onObserve: IntersectionObserverCallback = (
      entries: IntersectionObserverEntry[]
    ): void => {
      if (isElementActiveAndVisible(entries[0])) {
        sideNavBarSetFocus(sideLinkIndex, listItems);
      }
    };

    const observer = new IntersectionObserver(onObserve, {
      
      threshold: window.innerHeight < 1100 ? 0.1 : 0.4,
      rootMargin: '0px 0px -100px 0px',
    });
    observer.observe(observationDiv);
  };

  // sets focus to the element that has the index
  const sideNavBarSetFocus = (sideLinkIndex: number, listItems) => {
    if (listItems?.length) {
      const curActiveLink = document.querySelector(
        `.${CONSTANTS.navClassName} .${CONSTANTS.activeLinkClassName}`
      )! as HTMLElement;

      curActiveLink?.classList.remove(CONSTANTS.activeLinkClassName);
      listItems[sideLinkIndex]?.classList.add(CONSTANTS.activeLinkClassName);
      CONSTANTS.activeSectionIndex = sideLinkIndex;
    }
  };

  const initSideNavigation = () => {
    const allLinks = document.querySelectorAll(
      `.${CONSTANTS.navClassName} .emu-navigation__item a`
    ) as NodeListOf<HTMLElement>;
    const allLinkListItems = document.querySelectorAll(
      `.${CONSTANTS.navClassName} .emu-navigation__item`
    );

    allLinks.forEach((link, i) => {
      const divId = link.getAttribute('href')?.replaceAll('#', '')! as string;
      const section = document.getElementById(divId);
      if (section) {
        // @ts-ignore
        CONSTANTS.sections.push(section);
        // @ts-ignore
        CONSTANTS.listItems.push(allLinkListItems[i]);
        // @ts-ignore
        CONSTANTS.links.push(link);

        bindSectionLink(section, i, allLinkListItems);

        // making sure that hash is not added to the URL
        link.addEventListener('click', e => {
          e.preventDefault();
        });
      }
    });
  };

  const init = () => {
    const jdNav = document.querySelector(`.${CONSTANTS.navClassName}`);
    if (jdNav) {
      const firstLinkItem = jdNav.querySelector(
        `.${CONSTANTS.navClassName} .emu-navigation__item`
      );

      if (firstLinkItem) {
        firstLinkItem.classList.add(CONSTANTS.activeLinkClassName);
      }

      initSideNavigation();
    }
  };

  window.addEventListener('load', init);

})();
