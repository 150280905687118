(() => {
  const CONSTANTS = {
    mainWrapperClass: 'img-map',
    mainWrapperReadyClass: 'img-map--rendered',
    markerClass: 'emu-mapping-marker',
    contentClass: 'img-map__content',
    contentActiveClass: 'img-map__content--active',
    markerActiveClass: 'emu-mapping-marker--active',
    bodyHasActiveHotspotClass: 'has-active-img-map-hotspot',
  };

  let progressTimer;

  // adds progress bar and clicks on marker to close the hotspot content, once the timer ran out
  const appendProgress = markerObj => {
    const cta = markerObj?.marker as HTMLElement;
    if (cta) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.classList.add('progress');
      const svgContent = `<circle cx="${(cta.clientWidth + 8) / 2}" cy="${
        (cta.clientHeight + 8) / 2
      }" r="${(cta.clientHeight + 2) / 2}"></circle>`;
      svg.innerHTML = svgContent;
      cta.append(svg);

      progressTimer = setTimeout(() => {
        markerObj.marker.click();
      }, 5000);
    }
  };

  // removes the progress bar around the icon, and remove alignment class
  const removeProgress = markerObj => {
    if (progressTimer) {
      clearInterval(progressTimer);
      progressTimer = null;
    }

    const svg = markerObj.marker.querySelector('.progress');
    svg?.remove();
    markerObj?.content?.classList.remove(
      `${CONSTANTS.contentClass}--right-align`
    );
  };

  // custom actions when a image map marker gets active or inactive
  const handleCustomImgMapActions = () => {
    window.Bus?.on('emu-marker-active', e => {
      appendProgress(e);
    });

    window.Bus?.on('emu-marker-inactive', e => {
      removeProgress(e);
    });
  };

  // Adds events to add or remove classes to the content, the marker and the body
  const handleImgMap = imgMap => {
    if (imgMap) {
      imgMap
        .closest('.img-map')
        ?.classList?.add(CONSTANTS.mainWrapperReadyClass);
      const markers = imgMap.querySelectorAll(`.${CONSTANTS.markerClass}`);
      const markerMap = {};
      const actualImageMap = imgMap;
      const body = document.body;

      if (!actualImageMap) {
        return;
      }
      const actualImageMapId = actualImageMap.id;
      let activeKey: string = '';
      let timer;

      // adjusts the position of the content
      // if the content's left most pixel is out of the main wrapper's left most pixel, change the alignment of the content to the right
      const adjustContentPos = ({ content }) => {
        const imgMapLeft = imgMap.getBoundingClientRect()?.left;
        if (content) {
          const contentLeft = content.getBoundingClientRect()?.left;

          if (contentLeft < imgMapLeft) {
            content.classList.add(`${CONSTANTS.contentClass}--right-align`);
          } else {
            content.classList.remove(`${CONSTANTS.contentClass}--right-align`);
          }
        }
      };

      markers.forEach(marker => {
        const markerLabel = marker.getAttribute('aria-label');
        const content = imgMap
          .closest('.img-map')
          .querySelector(`.${CONSTANTS.contentClass}--${markerLabel}`);

        if (markerLabel && content) {
          // setting default position for the content
          content.style.top = marker.style.top;
          content.style.left = marker.style.left;

          markerMap[markerLabel] = {
            content,
            markerLabel,
            marker,
          };
        }
      });

      // closing the active hotspot when resized
      let winWidth = window.innerWidth;
      window.addEventListener('resize', () => {
        const curWinWidth = window.innerWidth;
        if (curWinWidth !== winWidth) {
          winWidth = curWinWidth;
          makeMarkerObjInactive();
        }
      });

      // makes a marker active, adds appropriate classes to the body, marker and corresponding content
      const makeMarkerObjActive = markerObj => {
        adjustContentPos(markerObj);
        activeKey = markerObj.markerLabel;
        markerObj.content.classList.add(CONSTANTS.contentActiveClass);
        markerObj.marker.classList.add(CONSTANTS.markerActiveClass);
        body.classList.add(CONSTANTS.bodyHasActiveHotspotClass);
        window.Bus.emit('emu-marker-active', markerObj);

        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          // listens to the body event to make an active marker inactive when body is clicked
          body.addEventListener(
            'click',
            e => {
              const target = e.target as HTMLElement;
              const closestMarker = target?.closest?.(
                `.${CONSTANTS.markerClass}`
              );
              if (!closestMarker) {
                makeMarkerObjInactive();
              }
            },
            {
              once: true,
            }
          );
        }, 100);
      };

      // makes a marker inactive for a provided marker Object
      // if marker object is not provided, loops through the map, finds the active marker and makes it inactive
      const makeMarkerObjInactive = (markerObj?) => {
        let obj = markerObj;
        if (!obj) {
          Object.keys(markerMap).forEach(markerKey => {
            if (activeKey === markerKey) {
              obj = markerMap[markerKey];
            }
          });
        }

        if (obj?.marker) {
          activeKey = '';
          obj.content.classList.remove(CONSTANTS.contentActiveClass);
          obj.marker.classList.remove(CONSTANTS.markerActiveClass);
          body.classList.remove(CONSTANTS.bodyHasActiveHotspotClass);
          window.Bus.emit('emu-marker-inactive', obj);
        }
      };

      // makes a marker active/inactive
      const handleMapAreaClick = title => {
        const markerObj = markerMap[title];
        if (markerObj) {
          if (activeKey && activeKey === title) {
            makeMarkerObjInactive(markerObj);
          } else {
            if (activeKey) {
              makeMarkerObjInactive();
            }
            makeMarkerObjActive(markerObj);
          }
        }
      };

      window.Bus.on('emu-image-map:areaClick', ({ id, title }) => {
        if (id?.includes?.(actualImageMapId)) {
          handleMapAreaClick(title);
        }
      });
    }
  };

  const init = () => {
    // Wait until the image map becomes available and then start adding logic for managing click events of the buttons in image map
    const imgMapElems = document.querySelectorAll('.img-map');

    if (imgMapElems?.length) {
      // image map markers take time to be populated on the DOM - by AAAEM Common code
      // this listener waits for changes in an element and resolves with a promise when the element is found
      const waitForElm = (selector, parent?) => {
        return new Promise(resolve => {
          const wrapper = parent || document;
          const observeParent = parent || document.body;
          let el = wrapper.querySelector(selector);

          if (el) {
            resolve(el);
            return;
          }

          const observer = new MutationObserver(() => {
            if (wrapper?.querySelector(selector)) {
              resolve(wrapper.querySelector(selector));
              observer.disconnect();
            }
          });

          observer.observe(observeParent, {
            childList: true,
            subtree: true,
          });
        });
      };

      imgMapElems.forEach(imgMap => {
        waitForElm('.emu-mapping-marker', imgMap).then(() => {
          const imgs = imgMap.querySelectorAll('.emu-image-map');
          if (imgs?.length) {
            imgs.forEach(img => {
              handleImgMap(img);
            });
          }
        });
      });
    }

    if (window.Bus) {
      handleCustomImgMapActions();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
